export default {
  methods: {
    getCompanyLogo () {
      return process.env.VUE_APP_COMPANY_LOGO
    },
    getProfileLogo () {
      return process.env.VUE_APP_PROFILE_LOGO
    }
  }
}
